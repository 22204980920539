.careerSection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
}

.locationsSection {
    width: 34%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .09);
    border-radius: 8px;
}

.servicesSection .careerSectionHeading {
    margin-bottom: 40px;
}

.servicesSection .careerSectionHeading h2 {
    font-style: normal;
    font-weight: 510;
    font-size: 52px;
    line-height: 62px;
    color: #000000;
    margin-bottom: 12px;
}

.p-20 {
    padding: 10px 20px;
}

.locationSectionHeading h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    color: #000000;
    margin-bottom: 12px;
    /* cursor: pointer; */

}

.jobItem {
    font-style: normal;
    font-size: 18px;
    color: #000000;
    margin-bottom: 12px;
    cursor: pointer;
    /* border-bottom: 1px solid rgb(225, 217, 217); */
}

.jobItem::marker {
    color: rgb(225, 217, 217);
}

.jobDescription {
    width: 65%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .09);
    /* border:1px solid saddlebrown; */
    border-radius: 8px;
    padding: 20px;
}

.jobDescription h4 {
    font-style: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
    margin-bottom: 12px;
}

.job_heading_sec {
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(225, 217, 217);
}

.skill_heading {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin-bottom: 12px;
}

.job_body_sec {
    padding-top: 10px;
}

.btn_sec {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.skill_item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* color: #9a9696; */
    margin-bottom: 12px;
}

.sub_heading {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-bottom: 12px;
}

.upload_btn {
    padding: 6px 10px;
    color: #F2F2F2 !important;
    border-radius: 100px;
    background-color: #225085 !important;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    font-style: normal;
}

@media(max-width: 640px) {

    .careerSection {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .locationsSection {
        width: 100%;
        margin-bottom: 10px;
    }

    .jobDescription {
        width: 100%;
    }
}

.upload-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .upload-button {
    background-color: white;
    border: 2px solid #225085;
    border-radius: 15px;
    color: #225085;
    padding: 4px 10px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #225085;
    color: white;
  }
  
  .file-hint {
    color: #999;
    font-size: 14px;
    /* margin-top: 8px; */
  }
  .file-name{
    font-size: 14px;
    margin-top: 8px;
    font-style :normal;
  }
  .btn_hint{
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  