body {
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container{
    width: 80%;
    margin: auto;
    
}

h1 {
    text-align:center;
    padding-bottom: 2rem;
    padding-top: 2rem;
}
h3{
    font-size: 2rem;
}
.introduction-para, th {
    text-align: justify;
    padding-bottom: 2rem;
    font-size: 1.5rem;
}
.specifications {
    padding-bottom: 10rem;
}
.table-alignment {
    text-align: left;
}
.table.table-bordered th{
    background-color: #225085;
    color: white;
}
.table.table-hover tbody tr:hover {
    background-color: rgb(34, 80, 133, 0.1);
    color:black;
}
.table-headings {
    font-size:2rem;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
}

.table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th {
    border: 1px solid #ddd;
}
tr {
    display: table-row;
    vertical-align: inherit;
    unicode-bidi: isolate;
    border-color: inherit;
}
.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}
.table-bordered {
    border: 1px solid #ddd;
}
.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
table {
    background-color: transparent;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
